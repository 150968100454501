/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import classNames from 'classnames';
import { Header, Footer, Responsive, VideoBlock } from 'client/components/common';
import { type EnhancedProps, withTransition } from 'client/components/common/HOCs/with-transition';

import bg from 'assets/client/images/main-bg-desktop.jpg';
import bgMob from 'assets/client/images/mobile_bg.png';
import logo from 'assets/client/images/logo-desktop.png';

import { texts } from 'client/constants';
import ContentForm from 'client/components/common/ContentForm';
import AlreadyInSystemPopup from 'client/components/common/Popups/AlreadyInSystemPopup';
import ClosedActivityPopup from 'client/components/common/Popups/ClosedActivityPopup';
import * as css from './Intro.scss';

type Props = {|
	...EnhancedProps,
	className?: string,
	in: boolean,
|};

type State = {
	isShowAlreadyInSystemPopup: boolean,
};
class Intro extends React.PureComponent<Props, State> {
	transitionCompleteTimeout: TimeoutID;

	static defaultProps = {
		className: '',
	};

	state = {
		isShowAlreadyInSystemPopup: false,
	};

	componentDidMount() {
		const isCreatedLead = localStorage.getItem('ytlm');

		if (isCreatedLead) {
			this.setState({
				isShowAlreadyInSystemPopup: true,
			});
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.in !== prevProps.in) {
			const { onTransitionComplete } = this.props;
			let duration = parseInt(this.props.in ? css.transitionIn : css.transitionOut, 10);

			if (!this.props.in) {
				duration = 0;
			}
			clearTimeout(this.transitionCompleteTimeout);
			this.transitionCompleteTimeout = setTimeout(onTransitionComplete, duration);
		}
	}

	render() {
		const { className, transitionState } = this.props;
		const { isShowAlreadyInSystemPopup } = this.state;
		const isMobile = Responsive.isMatching(Responsive.MOBILE);
		const descriptionMobile = (
			<>
				מי מאיתנו יכול לשכוח את המתכונים האהובים של סבתא: אוכל טעים, ביתי וחם, שעושה טוב על הלב, לא פחות מאשר
				בפה. עכשיו יש לכם הזדמנות לקחת חלק בפרויקט ייחודי - "פרויקט סבתא" מבית ערוץ האוכל בהשתתפות הסבתות
				הבשלניות שכולם מבקשים לטעום את המאכלים שלהן ולקבל מהן את המתכונים!
			</>
		);
		return (
			<div className={classNames(css.intro, css[transitionState], className)}>
				<Header isMobile={Responsive.isMatching(Responsive.MOBILE)} />
				<div className={css.content} style={{ backgroundImage: `url(${!isMobile ? bg : bgMob})` }}>
					<div className={css.contentInner}>
						<div className={`${css.mainLogo}`}>
							<img src={logo} alt="logo" />
						</div>
						<div className={css.mainContent}>
							<h1 className={css.title}>{texts.introTitle}</h1>
							<p className={css.description}>
								{!isMobile ? (
									<>
										מי מאיתנו יכול לשכוח את המתכונים האהובים של סבתא: אוכל טעים, ביתי וחם, שעושה טוב
										על הלב, לא
										<br /> פחות מאשר בפה. עכשיו יש לכם הזדמנות לקחת חלק בפרויקט ייחודי - "פרויקט
										סבתא" מבית ערוץ האוכל, בהשתתפות הסבתות הבשלניות שכולם מבקשים לטעום את המאכלים
										שלהן ולקבל מהן את המתכונים!
									</>
								) : (
									descriptionMobile
								)}
							</p>
							<VideoBlock />
							<p className={css.description2}>
								מכירים סבתא עם מתכון מנצח שחייבת לקחת חלק בפרויקט, מלאו את הפרטים הבאים:
							</p>
							<ContentForm />
						</div>
					</div>
				</div>
				<Footer />
				<AlreadyInSystemPopup className={isShowAlreadyInSystemPopup ? 'show' : ''} />
				<ClosedActivityPopup className="show" />
			</div>
		);
	}
}

export default withTransition(Intro);
