// @flow

import * as React from 'react';
import classNames from 'classnames';
import ynetLogoSrc from 'assets/client/images/ynet-logo.svg';
import logoFood from 'assets/client/images/logo-food.png';
import smallLogo from 'assets/client/images/small_logo.png';

import AccessibilityIcon from '../AccessibilityIcon';

import Socials from '../Socials';
import * as css from './Header.scss';

type Props = {
	className?: string,
	data?: any,
	disableLogo?: boolean,
	fixed?: boolean,
	isLogoShow?: boolean,
	isMobile: boolean,
};

const Header = ({ data, className, disableLogo, fixed, isLogoShow, isMobile }: Props) => {
	const ynetLink = 'https://www.ynet.co.il/';
	const foodLink =
		'https://www.goodlifetv.co.il/food-channel/?utm_source=ynet&utm_medium=ynet&utm_campaign=savta-project';

	return (
		<div className={classNames(css.header, className, fixed && css.fixed, isLogoShow && css.showLogo)} id="header">
			<div className={css.headerWrapper}>
				<div className={css.rightSide}>
					<a href={ynetLink} className={css.ynetLogo} rel="noopener noreferrer" target="_blank">
						<img src={ynetLogoSrc} alt="ynet-logo" />
					</a>
					<a href={foodLink} className={css.telmaLogo} rel="noopener noreferrer" target="_blank">
						<img src={logoFood} alt="logo-food" />
					</a>
					<a href="/" className={css.tableLogo} rel="noopener noreferrer" target="_blank">
						<img src={smallLogo} alt="logo-table" />
					</a>
				</div>
				<div className={css.leftSide}>
					<div className={css.sociallsWrapper}>
						<Socials minified={isMobile} />
						<AccessibilityIcon />
					</div>
				</div>
			</div>
		</div>
	);
};

Header.defaultProps = {
	className: '',
	data: {},
	disableLogo: false,
	fixed: false,
	isLogoShow: true,
};

export { Header };
