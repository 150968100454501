import { CAN_USE_DOM } from '../constants';

export const dataLayerEvents = {
	/**
	 * @param label {String}
	 */
	onShareBtnClick: label =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'share_events',
			Category: 'Social',
			Action: 'Share From Site Head',
			Label: label,
		}),
	onFormSended: label =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'form_events',
			Category: 'Form',
			Action: 'Form sent',
			Label: label,
		}),
	onClickButton: () =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_events',
			Category: 'Navigation',
			Action: 'Click to leave details',
			Label: '',
		}),
	videoView: action =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_events',
			Category: 'Video',
			Action: action,
			Label: 'Project Savta – promo video',
		}),
};
