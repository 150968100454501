/* eslint-disable max-len */
// @flow
import { connect } from 'react-redux';
import _ from 'lodash';
import * as React from 'react';
import classNames from 'classnames';
import validator from 'validator';

import { LEAD_CONSTANTS, CAN_USE_DOM } from 'common/constants';
import Icon from 'common/components/Icon';

import { PLACEHOLDERS_TEXTS, COLLECTIONS, ERRORS_TEXTS } from 'client/constants';
import { Form, TextField, MediaField, Checkbox, Button } from 'client/components/common';
import { initialize, SubmissionError } from 'redux-form';
import { createItem } from 'client/actions';
import { dataLayerEvents } from 'common/utils/gtag';
import termsPdfFile from 'assets/client/Terms.pdf';
// import terms from 'assets/client/terms.pdf';
import ErrorsPopup from '../Popups/ErrorsPopup';
import SendedFormPopup from '../Popups/SendedFormPopup';
import AlreadyInSystemPopup from '../Popups/AlreadyInSystemPopup';
import FileSizeLimitPopup from '../Popups/FileSizeLimitPopup';
import * as css from './ContentForm.scss';

// eslint-disable-next-line no-useless-escape
const phoneRegExp = /^\+?(972|0)(\-)?0?(([23489]{1}\d{7})|[5]{1}\d{8})$/;
// eslint-disable-next-line
const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// const onlyHebrewPatternWithNumbers = new RegExp(/^[\u0590-\u05FF 0-9 /\W/g\r\n-]+$/);
// const onlyHebrewPattern = new RegExp(/^[\u0590-\u05FF ,.'-]+$/i);
type FormData = {
	fullName?: string,
	age?: number,
	phoneNumber?: string,
	address?: string,
	email?: string,
	cookingStyle?: string,
	mainDish?: string,
	checkbox?: boolean,
	dishImg?: FileURL,
};

type Props = {
	values: FormData,
	errors: FormData,
	createItem: typeof createItem,
};

type State = {
	isUserNotified: boolean,
	isShowSenededFormPopup: boolean,
	isShowAlreadyInSystemPopup: boolean,
	isShowFileSizeLimit: boolean,
	isLoading: boolean,
};

// const maxWordLength = (value: string, maxWords: number) => {
// 	if (value === undefined) return maxWords + 1 < 0;

// 	return value.toString().split(' ').length > maxWords + 1;
// };

const FORM_CONFIG = {
	form: 'userInfo',
	// validate: values => {
	// 	const errors = {};
	// 	if (!values.initiativeName) {
	// 		errors.initiativeName = `שם היוזמה – <span>נדרש למלא את שם היוזמה</span>`;
	// 	}
	// 	return errors;
	// },
};

class ContentForm extends React.PureComponent<Props, State> {
	// static defaultProps = {
	// 	// className: '',
	// };

	constructor(props: Props) {
		super(props);
		this.state = {
			isUserNotified: false,
			isShowSenededFormPopup: false,
			isShowAlreadyInSystemPopup: false,
			isShowFileSizeLimit: false,
			isLoading: false,
		};
	}

	initFormValues = async () => {};

	onFormSubmit = async (values: FormData) => {
		this.setState({
			isUserNotified: false,
			isLoading: true,
		});

		const errorsObj = {};

		if (!values.fullName) {
			errorsObj.fullName = ERRORS_TEXTS.fullName;
		}

		if (!values.address) {
			errorsObj.address = ERRORS_TEXTS.address;
		}

		if (!values.age) {
			errorsObj.age = ERRORS_TEXTS.age;
			if (values.age && values.age < 1900 && values.age > 2021) errorsObj.age = ERRORS_TEXTS.age;
		}

		if (!values.phoneNumber) {
			errorsObj.phoneNumber = ERRORS_TEXTS.phoneNumber;
		} else if (values.phoneNumber) {
			const validPhone = phoneRegExp.test(values.phoneNumber.toLocaleLowerCase());
			if (!validPhone) {
				errorsObj.phoneNumber = ERRORS_TEXTS.phoneNumberNotValid;
			}
		}

		if (!values.email) {
			errorsObj.email = ERRORS_TEXTS.email;
		} else if (values.email) {
			const validEmail = validator.isEmail(values.email);
			if (!validEmail) {
				errorsObj.email = ERRORS_TEXTS.emailNotValid;
			}
		}

		if (!values.checkbox) {
			errorsObj.checkbox = ERRORS_TEXTS.checkbox;
		}

		if (!values.cookingStyle) {
			errorsObj.cookingStyle = ERRORS_TEXTS.cookingStyle;
		}

		if (!values.mainDish) {
			errorsObj.mainDish = ERRORS_TEXTS.mainDish;
		}

		if (!_.isEmpty(errorsObj)) {
			const errosWithoutCheckboxes = _.omit(errorsObj, 'checkbox');
			if (errorsObj.checkbox) {
				dataLayerEvents.onFormSended('Fail - חסרה הסכמה בצ’קבוקס');
				this.setState({
					isLoading: false,
				});
			} else if (_.size(errosWithoutCheckboxes) > 0) {
				dataLayerEvents.onFormSended('Fail - חסרים פרטים');
				this.setState({
					isLoading: false,
				});
			}

			throw new SubmissionError(errorsObj);
		}

		if (_.isEmpty(errorsObj)) {
			const leadResult = await this.props.createItem<any>({ collection: COLLECTIONS.LEADS, data: values });

			// if (leadResult.lead === LEAD_CONSTANTS.FOUNDED) {
			// 	this.setState({
			// 		isShowAlreadyInSystemPopup: true,
			// 		isLoading: false,
			// 	});
			// 	dataLayerEvents.onFormSended(window.location.href);
			// 	localStorage.setItem('ytlm', 'true');
			// }

			if (leadResult.lead === LEAD_CONSTANTS.CREATED) {
				this.setState({
					isShowSenededFormPopup: true,
					isLoading: false,
				});
				dataLayerEvents.onFormSended(window.location.href);
				localStorage.setItem('ytlm', 'true');
			}
		}
	};

	closeErrorPopup = () => {
		this.setState({
			isUserNotified: true,
		});
	};

	render() {
		const { errors } = this.props;
		const { isUserNotified, isShowSenededFormPopup, isShowAlreadyInSystemPopup, isShowFileSizeLimit } = this.state;

		let macOs;

		if (CAN_USE_DOM) {
			macOs = /(Mac)/i.test(navigator.platform);
		}

		// const wordCount = (value, limit) => {
		// 	if (value === undefined) return 0;
		// 	return value.trim().split(' ').length;
		// };

		return (
			<div className={css.formWrapper}>
				<Form className={css.form} config={FORM_CONFIG} onSubmit={this.onFormSubmit}>
					<div className={classNames(css.formRow, css.multipleInputs)}>
						<div className={css.fieldsWrapper}>
							<div className={classNames(css.fieldWrapper)}>
								<TextField
									name="fullName"
									placeholder={PLACEHOLDERS_TEXTS.fullName}
									// pattern="^[\u0590-\u05fe]+$"
									pattern="^[\u0590-\u05FF /]+$"
								/>
							</div>
							<div className={classNames(css.fieldWrapper)}>
								<TextField
									name="age"
									placeholder={PLACEHOLDERS_TEXTS.age}
									pattern="[0-9]"
									maxLength={4}
									maxNumber={2021}
									minNumber={1900}
									isNumberField
									type="number"
								/>
							</div>
							<div className={classNames(css.fieldWrapper)}>
								<TextField
									name="phoneNumber"
									placeholder={PLACEHOLDERS_TEXTS.phone}
									pattern="[0-9-+]"
									isNumberField
									isPlusAtTheStart
									type="tel"
								/>
							</div>
						</div>
						<div className={classNames(css.fieldsWrapper, css.fieldWrapperSecond)}>
							<div className={classNames(css.fieldWrapper, css.mediumField)}>
								<TextField
									name="address"
									placeholder={PLACEHOLDERS_TEXTS.address}
									// pattern="^[\u0590-\u05fe ]+$"
								/>
							</div>

							<div className={classNames(css.fieldWrapper, css.mediumField)}>
								<TextField
									name="email"
									placeholder={PLACEHOLDERS_TEXTS.email}
									isEmailField
									type="email"
								/>
							</div>
						</div>
						<div className={css.fieldsWrapper}>
							<div className={classNames(css.fieldWrapper, css.mediumField)}>
								<TextField
									name="cookingStyle"
									placeholder={PLACEHOLDERS_TEXTS.cookingStyle}
									pattern="^[\u0590-\u05FF 0-9 /\W/g\r\n-]+$"
								/>
							</div>

							<div className={classNames(css.fieldWrapper, css.mediumField)}>
								<TextField
									name="mainDish"
									placeholder={PLACEHOLDERS_TEXTS.mainDish}
									pattern="^[\u0590-\u05FF 0-9 /\W/g\r\n-]+$"
								/>
							</div>
						</div>
					</div>
					<div className={classNames(css.formRow)}>
						<MediaField
							name="dishImg"
							placeholder={
								<>
									צרפו תמונה של המנה (<span>JPEG, PNG, GIF </span>
									עד <span>MB 5</span>)
								</>
							}
							onShowSizeLimitPopup={() => this.setState({ isShowFileSizeLimit: true })}
						/>
					</div>

					<div className={css.formRow}>
						<div className={classNames(css.checkboxWrapper)}>
							<Checkbox
								name="checkbox"
								label={
									<>
										קראתי הבנתי ומסכים/ה ל
										<a href={termsPdfFile} target="_blank" rel="noreferrer">
											תקנון
										</a>
										,{' '}
										<a
											href="https://z.ynet.co.il/short/content/2018/privacypolicy/terms.html"
											target="_blank"
											rel="noreferrer"
										>
											תנאי שימוש
										</a>
										,{' '}
										<a
											href="https://z.ynet.co.il/short/content/2018/privacypolicy/policy.html"
											target="_blank"
											rel="noreferrer"
										>
											מדיניות פרטיות
										</a>{' '}
										ונותן/ת <br /> הסכמתי לקבלת דברי פרסום מ-<span>ynet</span>
									</>
								}
							/>
						</div>
					</div>
					<div className={classNames(css.formRow, css.withButton)}>
						{this.state.isLoading && <Icon type="preloader" className={css.preloader} />}
						<Button
							className={classNames(css.submitBtn, !macOs && css.forWindows)}
							label={this.state.isLoading ? '' : 'שלח'}
							type="submit"
						/>
					</div>
				</Form>

				<ErrorsPopup
					className={!isUserNotified && !_.isEmpty(errors) ? 'show' : ''}
					closePopup={this.closeErrorPopup}
					errors={errors}
				/>
				<AlreadyInSystemPopup className={isShowAlreadyInSystemPopup ? 'show' : ''} />
				<SendedFormPopup className={isShowSenededFormPopup ? 'show' : ''} />
				<FileSizeLimitPopup
					className={isShowFileSizeLimit ? 'show' : ''}
					closePopup={() => this.setState({ isShowFileSizeLimit: false })}
				/>
			</div>
		);
	}
}

const mapState = (state: ClientStore) => {
	const values = 'form.userInfo.values';
	const errors = 'form.userInfo.submitErrors';

	return {
		values: _.get(state, `${values}`, {}),
		errors: _.get(state, `${errors}`, {}),
	};
};

const mapDispatch = {
	initializeForm: initialize,
	createItem,
};

export default connect(mapState, mapDispatch)(ContentForm);
