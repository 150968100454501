/* eslint-disable max-len */
// @flow

export const FILE_DEFAULT: FileURL = {
	remoteURL: '',
	file: { name: '', url: '' },
	selected: 'remoteURL',
};
export const IS_DEVEL = process.env.NODE_ENV !== 'production';

export const SHARE_CONSTANTS = {
	WHATSAPP: 'WhatsApp',
	TWITTER: 'Twitter',
	FACEBOOK: 'Facebook',
};

export const COLLECTIONS = {
	SETTINGS: 'settings',
	LEADS: 'leads',
};

export const PLACEHOLDERS_TEXTS = {
	fullName: 'שם מלא',
	phone: 'טלפון',
	age: 'שנת לידה',
	address: 'כתובת',
	email: 'כתובת מייל',
	message: '<span>[עד 500 תווים]</span>',
	cookingStyle: 'סגנון בישול אופייני <span>(לדוגמא: מטבח הונגרי)</span>',
	mainDish: 'שם מנת הדגל שלכם <span>(לדוגמא: קוסקוס מרוקאי)</span>',
};

export const ERRORS_TEXTS = {
	fullName: 'יש למלא שם מלא',
	age: 'יש למלא שנת לידה',
	email: 'יש למלא כתובת מייל',
	emailNotValid: 'יש למלא כתובת מייל תקינה',
	phoneNumber: 'יש למלא מספר טלפון',
	phoneNumberNotValid: 'יש למלא מספר טלפון תקין',
	recaptchaError: `<span>עלייך לאשר שאינך רובוט</span>`,
	address: 'יש למלא כתובת',
	cookingStyle: 'יש למלא סגנון בישול אופייני',
	mainDish: 'יש למלא את שם מנת הדגל שלכם',
	checkbox: 'יש לאשר את התקנון, מדיניות הפרטיות ותנאי השימוש',
};

export const texts = {
	introTitle: 'אין על האוכל של סבתא שלי!',
};
