import React, { useState, useRef } from 'react';
import { Link, Element } from 'react-scroll';
import { dataLayerEvents } from 'common/utils/gtag';
import playButton from 'assets/client/images/play.png';
import videoBg from 'assets/client/images/videoBg.jpg';
import css from './VideoBlock.scss';

export function VideoBlock() {
	const [isPlaying, setIsPlaying] = useState(false);
	const [isFirstTimePlaying, setIsFirstPlaying] = useState(false);
	const [isVideoEnd, setIsVideoEnd] = useState(false);
	const [videoSecondsPlaying, setVideoSecondPlaying] = useState({
		video25: false,
		video50: false,
		video75: false,
		video90: false,
		video100: false,
	});
	const video = useRef();
	const onPlayButtonClick = () => {
		setIsFirstPlaying(true);
		setIsPlaying(!isPlaying);
		if (!isPlaying) {
			video.current.play();
		}
	};
	const onTimeVideoChange = () => {
		// console.info(video.current.currentTime);
		const currentTime = video.current.currentTime.toFixed();
		if (currentTime > 15.5 && videoSecondsPlaying.video25 !== true) {
			setVideoSecondPlaying({ ...videoSecondsPlaying, video25: true });
			dataLayerEvents.videoView('Played 25%');
		}

		if (currentTime > 31 && videoSecondsPlaying.video50 !== true) {
			setVideoSecondPlaying({ ...videoSecondsPlaying, video50: true });
			dataLayerEvents.videoView('Played 50%');
		}

		if (currentTime > 46.5 && videoSecondsPlaying.video75 !== true) {
			setVideoSecondPlaying({ ...videoSecondsPlaying, video75: true });
			dataLayerEvents.videoView('Played 75%');
		}

		if (currentTime > 55.8 && videoSecondsPlaying.video90 !== true) {
			setVideoSecondPlaying({ ...videoSecondsPlaying, video90: true });
			dataLayerEvents.videoView('Played 90%');
		}

		if (currentTime > 61 && videoSecondsPlaying.video100 !== true) {
			setVideoSecondPlaying({ ...videoSecondsPlaying, video100: true });
			dataLayerEvents.videoView('Played 100%');
		}
	};

	const onClickButton = () => {
		dataLayerEvents.onClickButton();
	};

	const onVideoPlayClick = () => {
		dataLayerEvents.videoView('Play');
		if (isVideoEnd) {
			dataLayerEvents.videoView('Replay');
			setIsVideoEnd(false);
		}
	};
	const onVideoPauseClick = () => {
		dataLayerEvents.videoView('Pause');
	};

	const onVideoEnded = () => {
		setIsVideoEnd(true);
	};

	return (
		<div className={css.wrapper}>
			<Link to="video" duration={200}>
				<button className={css.button} type="button" onClick={onClickButton}>
					<span>מכירים סבתא עם מתכונים ששווים זהב?</span> הקליקו והשאירו פרטים
				</button>
			</Link>

			<Element name="video">
				<div className={css.videoWrapper}>
					<div
						className={css.videoPlank}
						style={{ display: isPlaying || isFirstTimePlaying ? 'none' : 'block' }}
						onClick={onPlayButtonClick}
					></div>
					<div
						className={css.videoBg}
						onClick={onPlayButtonClick}
						style={{ display: isPlaying ? 'none' : 'block' }}
					>
						<img
							src={videoBg}
							alt="Video background"
							className={css.videoBgImg}
							style={{ display: isFirstTimePlaying ? 'none' : 'block' }}
						/>
						<img
							src={playButton}
							alt="Play"
							className={css.videoPlayButton}
							style={{ display: isFirstTimePlaying ? 'none' : 'block' }}
						/>
					</div>
					<video
						src="https://progressive-video.ynet.co.il/1221/4aebbe219d6b5ef362197e774f19dbb3-hd720.mp4"
						ref={video}
						onClick={onPlayButtonClick}
						onTimeUpdate={e => onTimeVideoChange()}
						onPause={() => onVideoPauseClick()}
						onPlay={() => onVideoPlayClick()}
						onEnded={() => onVideoEnded()}
						controls={isFirstTimePlaying}
					>
						<track kind="captions"></track>
					</video>
				</div>
			</Element>
		</div>
	);
}
